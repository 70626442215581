import { Col, FlexGrid, List } from '@elseu/sdu-titan';
import { useMemo } from 'react';

import { BlockLink } from './BlockLink';
import type { BlockWithLinksProps } from './BlockWithLinks';
import { BlockWrapper } from './BlockWrapper';

export type BlockWithSplitLinksProps = BlockWithLinksProps;

export const BlockWithSplitLinks = ({
  links,
  listComponent,
  isPrimary,
  ...blockWrapperProps
}: BlockWithSplitLinksProps) => {
  const ListComponent = listComponent || List;

  const linksList = useMemo(() => {
    const blockLinks = links.map((item) => <BlockLink key={item.id} {...item} />);

    if (blockLinks.length <= 5) {
      return <ListComponent>{blockLinks}</ListComponent>;
    }

    const leftItems = blockLinks.slice(0, Math.ceil(blockLinks.length / 2));
    const rightItems = blockLinks.slice(Math.ceil(blockLinks.length / 2), blockLinks.length);

    return (
      <FlexGrid gutter={{ xs: 1, m: 10 }}>
        <Col width={{ xs: 1, s: 1 / 2 }}>
          <ListComponent>{leftItems}</ListComponent>
        </Col>
        <Col width={{ xs: 1, s: 1 / 2 }}>
          <ListComponent>{rightItems}</ListComponent>
        </Col>
      </FlexGrid>
    );
  }, [ListComponent, links]);

  return (
    <BlockWrapper isPrimary={isPrimary} {...blockWrapperProps}>
      {linksList}
    </BlockWrapper>
  );
};
