import type { SidebarItemProps } from '@elseu/sdu-titan';
import { ContentWrapper, media, spacing } from '@elseu/sdu-titan';
import type { BlockLinkProps } from '@elseu/sdu-titan-content-rendering';
import { BlockWithSplitLinks } from '@elseu/sdu-titan-content-rendering';
import { GenialStartBanner } from '@elseu/sdu-titan-genial';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { useSiteConfig } from '../../context';
import { useNavigation } from '../../context/NavigationProvider/NavigationProvider';
import { getBlockLinkBaseConfiguration } from '../../helpers/getBlockLinkBaseConfiguration';
import { Footer } from '../Footer/Footer';
import { MarketingBlock } from '../MarketingBlock/MarketingBlock';
import News from '../News/News';

const StyledPrimaryArea = styled.div`
  ${media.greaterThan('s')`
    margin-left: -${spacing(10)};
    margin-right: -${spacing(10)};
  `};
`;

const StyledBlockWithLinks = styled(BlockWithSplitLinks)`
  padding: ${spacing(6)};
  margin-left: -${spacing(6)};
  margin-right: -${spacing(6)};
  ${media.greaterThan('s')`
    padding: ${spacing(10)};
    margin-left: 0;
    margin-right: 0;
  `};
`;

const convertSidebarItemToBlockLink = ({ id, to, title }: SidebarItemProps): BlockLinkProps => ({
  ...getBlockLinkBaseConfiguration({ id, title }),
  to,
  type: 'link',
});

const DefaultHomePage = () => {
  useLingui();
  const { featureFlags, urls } = useSiteConfig();
  const { highlightedItem } = useNavigation();

  return (
    <ContentWrapper maxWidth={640} spaceAfter={8}>
      <StyledPrimaryArea>
        {!!featureFlags.WITH_BLUETICK_AI_CHAT && (
          <GenialStartBanner
            content={t`Beantwoord jouw onderzoeksvragen met behulp van AI`}
            href={urls.genialUrl}
          />
        )}
        <MarketingBlock />
        {highlightedItem && (
          <StyledBlockWithLinks
            backgroundColor="grey10"
            heading="h2"
            links={highlightedItem.children.map(convertSidebarItemToBlockLink)}
            padding={10}
            renderAs="h1"
            spaceAfter={{ xs: 10, s: 16 }}
            spaceAfterTitle={6}
            testId="delenBlock"
            title={highlightedItem.title}
          />
        )}
      </StyledPrimaryArea>
      <News />
      <Footer />
    </ContentWrapper>
  );
};

export default DefaultHomePage;
